<template>
  <div class="main">
    <heads></heads>
    <ctdkbanners></ctdkbanners>
    <div class="cons">
      <div class="xqmain">
        <div class="xqmain-maintitle">
          <div>全部期刊</div>
          <div>
            <el-button type="primary" @click="comeback">返回上级</el-button>
          </div>
        </div>

        <div class="ls-title">
          <div class="ls-title-zy"><span>标题：</span>{{ biaot }}</div>
          <div :class="iszhankai == true ? zhaiyaoclasszk : zhaiyaoclass">
            <span>摘要：</span>{{ zhaiy }}
            <div class="ls-title-zhank" @click="iszhankai = !iszhankai">
              {{ iszhankai == true ? "收起" : "展开" }}
            </div>
          </div>
          <div class="ls-title-zy"><span>关键词：</span>{{ guanjianci }}</div>
          <div class="ls-title-zy">
            <span>中图分类推荐：</span
            ><i
              class="ls-title-zy-i"
              v-for="(item, key) in ztflhtjlist"
              :key="key"
              >{{ item.flhname }}（{{ item.flh }}），</i
            >
          </div>
          <div class="ls-title-zy" v-if="txjgval != ''">
            <span>机构：</span>{{ txjgval }}
          </div>
        </div>

        <div class="tips">为您找到相关期刊共{{qikanlistshow.length}}本</div>

        <div class="allqk">
          <div
            class="allqk-list"
            v-for="(item, key) in qikanlistshow"
            :key="key"
            @click="ckqkxq(item.Id)"
          >
            <div class="allqk-list-img">
              <el-image
                style="width: 98px; height: 128px"
                :src="`${proxy.$imgurl}/qikanfmpic/${item.qkcode}.jpg`"
                :fit="'fill'"
                :title="item.qkname"
              ></el-image>
            </div>

            <div class="allqk-list-title">{{ item.qkname }}</div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { get, post } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();

let qikanlist = reactive([]);
// 需要显示的期刊
let qikanlistshow=reactive([])

let biaot = ref("");
let zhaiy = ref("");
let guanjianci = ref("");
// 展开收缩
let iszhankai = ref(false);
let zhaiyaoclass = ref("ls-title-zy ls-title-zynr");
let zhaiyaoclasszk = ref("ls-title-zy ls-title-zynr-zk");
// 中图分类号推荐
let ztflhtjlist = reactive([]);
let txjgval = ref("");

// 获取投稿发现
const gettgfx = () => {
  get("/journalRecommend/GetBynid", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // 基本属性
    biaot.value = res.data.btword;
    zhaiy.value = res.data.zyword;
    guanjianci.value = res.data.kwword;
    // 中途分类号
    ztflhtjlist.push(...JSON.parse(res.data.ztflhstr));
    // 机构
    if (res.data.writerorg && res.data.writerorg != "") {
      txjgval.value = res.data.writerorg;
    }

    qikanlist.length = 0;
    qikanlist.push(...JSON.parse(res.data.xgqk).qklist);
    // 默认分类优先
    changerad("xtcynumdouble-", 1);

    // console.log(qikanlist);
  });
};
gettgfx();

// 查看期刊
const ckqkxq = (id) => {
  const url = router.resolve({
    path: "/xkzs/del",
    query: {
      id: id,
    },
  });
  window.open(url.href);
};

// 选择排序方式
const changerad = (val, index) => {
  // 排序
  qikanlistshow.length = 0;
  switch (val) {
    case "xtcynumdouble-":
      qikanlistshow.push(...sortDownByKey(qikanlist, "xtcynumdouble"));
      break;
    default:
      qikanlistshow.push(...sortByKey(qikanlist, "yxyz"));
  }

};

//数组对象方法排序:降序
const sortDownByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });
};

//数组对象方法排序:升序
const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

// 返回
const comeback = () => {
  window.history.go(-1);
};
</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0 50px 0;
}
.xqmain-maintitle {
  border-bottom: 3px double #333;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
.allqk::after {
  content: "";
  display: table;
  clear: both;
}
.allqk-list-img {
  width: 98px;
  height: 128px;
  border: 1px solid #ccc;
}
.allqk-list-img:hover {
  border: 1px solid rgb(56, 125, 252);
}
.allqk-list {
  float: left;
  width: 100px;
  height: 150px;
  margin: 10px 10px 0 10px;
  cursor: pointer;
}
.allqk-list-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
}

.ls-title {
  width: 1200px;
  margin: auto;
  padding: 0 10px 0 10px;
}

.ls-title-zy {
  position: relative;
  padding: 10px 15px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
  line-height: 24px;
  margin-bottom: 5px;
  font-size: 14px;
}
.ls-title-zynr {
  height: 63px;
  padding: 10px 60px 10px 15px;
  overflow: hidden;
}
.ls-title-zynr-zk {
  height: auto;
  padding: 10px 60px 10px 15px;
  overflow: hidden;
}
.ls-title-bt {
  font-size: 28px;
  margin-bottom: 15px;
}
.ls-title-zy > span {
  font-weight: bold;
}
.ls-title-zy-i {
  font-style: normal;
}
.ls-title-zhank {
  position: absolute;
  right: 0;
  top: 5px;
  width: 60px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
}
.tips{
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #999;
}
</style>
